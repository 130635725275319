import React, { useEffect } from "react";
import BlocksContent from "@components/shared/sub/blockContent";
import { useStaticQuery, graphql } from "gatsby";
import SEO from "@components/shared/seo";

import LayOut from "@components/layout";
import { Container } from "@util/standard";

const PrivacyPolicy = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const { sanityPolicyPage } = useStaticQuery(graphql`
    query policyContent {
      sanityPolicyPage {
        content {
          _rawColumnContent
        }
        cookieContent {
          _rawColumnContent
        }
        seo {
          pageTitle
          pageKeyWords
          pageDescription
        }
      }
    }
  `);

  return (
    <>
      <SEO seoData={sanityPolicyPage.seo} />

      <LayOut>
        <Container width="90%" margin="50px auto">
          <div className="privacy-content">
            <h1>Website Privacy Policy</h1>
            <div className="main-content">
              <BlocksContent
                blocks={sanityPolicyPage.content._rawColumnContent}
              />
            </div>
            <h1>Cookie Policy</h1>
            <div className="main-content">
              <BlocksContent
                blocks={sanityPolicyPage.cookieContent._rawColumnContent}
              />
            </div>
          </div>
        </Container>
      </LayOut>
    </>
  );
};

export default PrivacyPolicy;
